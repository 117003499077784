"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MainAxisType = void 0;
exports.isMainAxis = isMainAxis;
var MainAxisType;
(function (MainAxisType) {
    MainAxisType["category"] = "category";
    MainAxisType["time"] = "time";
})(MainAxisType || (exports.MainAxisType = MainAxisType = {}));
function isMainAxis(axis) {
    return axis && (axis.data || (axis.type && Object.values(MainAxisType).includes(axis.type)));
}
